import {
  addErrorHandler,
  getAppStatus,
  LifeCycles,
  LOAD_ERROR,
  registerApplication,
  start,
} from "single-spa";
import { props } from "./config/props";

registerApplication({
  name: "@gclient",
  app: () => System.import<LifeCycles>("@gd/gclient"),
  activeWhen: ["/clientes"],
  customProps: props,
});

registerApplication({
  name: "@common",
  app: () => System.import<LifeCycles>("@gd/common"),
  activeWhen: (location: Location) =>
    !location.pathname.includes("/clientes") &&
    !location.pathname.includes("/barter"),
  customProps: props,
});

registerApplication({
  name: "@header",
  app: () =>
    System.import<any>("@gd/header").then((res) => ({
      bootstrap: res.headerBootstrap,
      mount: res.headerMount,
      unmount: res.headerUnmount,
    })),
  activeWhen: ["/"],
  customProps: props,
});

registerApplication({
  name: "@sidenav",
  app: () =>
    System.import<any>("@gd/header").then((res) => {
      return {
        bootstrap: res.sidenavBootstrap,
        mount: res.sidenavMount,
        unmount: res.sidenavUnmount,
      };
    }),
  activeWhen: ["/"],
  customProps: props,
});

registerApplication({
  name: "@barter",
  app: () => System.import<LifeCycles>("@gd/barter"),
  activeWhen: (location) => location.pathname.startsWith("/barter"),
  customProps: props,
});

registerApplication({
  name: "@gc-pro",
  app: () => System.import<LifeCycles>("@gd/gc-pro"),
  activeWhen: (location) =>
    location.pathname.startsWith("/gestao-de-contratos"),
  customProps: props,
});

addErrorHandler((err) => {
  console.log(err);
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    System.delete(System.resolve(err.appOrParcelName));
  }
});

start({
  urlRerouteOnly: true,
});
